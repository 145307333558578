<template>
  <div class="p-4 lg:py-12 lg:px-20">
    <h1 class="text-2xl font-bold font-mulish">
      Create External Dashboard link
    </h1>
    <small class="text-gray-800">
      Enter the details of the person that will have access to the dashboard
      link
    </small>

    <form @submit.prevent="proceed" class="max-w-[600px] w-full">
      <div class="mt-5">
        <label for="firstName">First name</label>
        <input
          type="text"
          class="input"
          v-model="form.firstName"
          placeholder="enter first name"
        />
      </div>

      <div class="mt-5">
        <label for="lastName">Last name</label>
        <input
          type="text"
          class="input"
          v-model="form.lastName"
          placeholder="enter last name"
        />
      </div>

      <div class="mt-5">
        <label for="email">Email Address</label>
        <input
          type="email"
          class="input"
          v-model="form.emailAddress"
          placeholder="enter email"
        />
      </div>

      <div class="mt-5">
        <label for="phone">Phone number</label>
        <input
          type="text"
          class="input"
          v-model="form.phoneNumber"
          placeholder="enter phone number"
        />
      </div>

      <div class="mt-7">
        <button
          :disabled="!formReady || proceeding"
          type="submit"
          class="bg-idBlued text-white text-sm lg:text-base w-full py-3 rounded"
        >
          <spinner class="" v-if="proceeding"></spinner>
          <span>Proceed</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "CreateDashboardLink",

  data() {
    return {
      proceeding: false,
      form: {
        firstName: null,
        lastName: null,
        emailAddress: null,
        phoneNumber: null,
        baseUrl: `${window.location.origin}/dashboard`,
      },
    };
  },

  computed: {
    formReady() {
      return (
        this.form.firstName &&
        this.form.lastName &&
        this.form.emailAddress &&
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.form.emailAddress) &&
        this.form.phoneNumber &&
        /^(\+[\d]{1,13}|0[\d]{1,11})$/.test(this.form.phoneNumber)
      );
    },
  },

  methods: {
    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all input",
        });
        return;
      }

      try {
        this.proceeding = true;
        const res = await this.$http.post("/adhoc/create-user", this.form);
        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.form.firstName = null;
        this.form.lastName = null;
        this.form.emailAddress = null;
        this.form.phoneNumber = null;
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped></style>
