<template>
  <div
    class="w-full lg:w-[800px] lg:m-10 p-2 lg:ml-[116px] lg:mt-[50px] flex flex-col item-start justify-start"
  >
    <h1 class="font-mullish text-dkgray text-[24px] font-bold m-0">
      System Settings
    </h1>

    <div class="w-full mt-4">
      <form class="flex justify-between flex-col md:flex-row gap-5 items-end">
        <div class="w-full">
          <label>Action<span class="req">*</span> </label>
          <v-select
            :reduce="(b) => b.action"
            :options="actions"
            label="name"
            v-model="form.action"
            placeholder="-- select --"
          ></v-select>
        </div>
        <div class="w-full" v-if="form.action !== 'bypass-microfinance'">
          <label> Verification Number<span class="req">*</span> </label>
          <input
            v-model="form.verificationCode"
            type="text"
            v-uppercase
            class="input"
          />
        </div>
        <div class="w-[250px]">
          <button
            v-if="form.action === 'bypass-microfinance'"
            :disabled="processing"
            class="proceed-btn"
            @click="submit"
          >
            <span>Proceed</span>
            <spinner v-if="processing"></spinner>
          </button>
          <button
            v-else
            class="proceed-btn"
            :disabled="processing || !formReady"
            @click="fetchDetails"
          >
            <span>Search</span>
            <spinner v-if="processing"></spinner>
          </button>
        </div>
      </form>
      <span class="font-bold text-[red] text-xs" v-if="showError"
        >You can only rematch and update bvn for one staff</span
      >
    </div>

    <div
      v-if="form.action !== 'bypass-microfinance'"
      class="flex justify-between flex-col md:flex-row mt-8 gap-4"
    >
      <div class="border border-gray-300 rounded min-h-[290px] w-full p-4">
        <div
          class="h-full w-full flex justify-center items-center"
          v-if="!staffInfo"
        >
          <h1 class="font-mullish text-dkgray/50 italic font-bold m-0">
            Search for a staff
          </h1>
        </div>
        <div v-else>
          <h1
            class="font-mullish text-dkgray text-md font-bold m-0 text-center mb-2"
          >
            Staff Details
          </h1>

          <div class="divide-y">
            <div class="flex justify-between py-2 text-xs">
              <strong>Verification Code:</strong>
              <span>{{ staffInfo?.verificationCode }}</span>
            </div>
            <div class="flex justify-between py-2 text-xs">
              <strong>First Name:</strong>
              <input
                v-if="form.action === 'rematch-bvn-and-update-bvn'"
                type="text"
                v-model="rematchInfo.firstName"
                class="custome-input"
              />

              <span v-else>{{ staffInfo?.firstName }}</span>
            </div>
            <div class="flex justify-between py-2 text-xs">
              <strong>Last Name:</strong>
              <input
                v-if="form.action === 'rematch-bvn-and-update-bvn'"
                type="text"
                v-model="rematchInfo.lastName"
                class="custome-input"
              />
              <span v-else>{{ staffInfo?.lastName }}</span>
            </div>
            <div class="flex justify-between py-2 text-xs">
              <strong>Middle Name:</strong>
              <input
                v-if="form.action === 'rematch-bvn-and-update-bvn'"
                type="text"
                v-model="rematchInfo.middleName"
                class="custome-input"
              />
              <span v-else>{{ staffInfo?.middleName }}</span>
            </div>
            <div class="flex justify-between py-2 text-xs">
              <strong>Gender:</strong>
              <span>{{ staffInfo?.gender }}</span>
            </div>

            <div class="flex justify-between py-2 text-xs">
              <strong>Email:</strong>
              <span>{{ staffInfo?.email }}</span>
            </div>
            <div class="flex justify-between py-2 text-xs">
              <strong>Phone:</strong>
              <span>{{ staffInfo?.phoneNumber }}</span>
            </div>

            <div class="flex justify-between py-2 text-xs">
              <strong>MDA:</strong>
              <span>{{ staffInfo?.mdaName }}</span>
            </div>
          </div>

          <p v-if="!addReady" class="text-xs text-purple-400 font-bold mt-4">
            {{ addErrorMsg }}
          </p>
          <button
            class="bg-idBlued text-[12px] p-2 w-full mt-2 rounded m-auto text-[#fff] flex items-center justify-center"
            @click="addStaffToList"
            :disabled="processing || !addReady"
          >
            Add Staff
          </button>
        </div>
      </div>

      <div
        class="border border-gray-300 rounded min-h-[290px] w-full p-4 flex flex-col"
      >
        <div class="flex-grow">
          <h1
            class="font-mullish text-dkgray underline text-md font-bold m-0 text-center mb-2"
          >
            Selected Staff Members
          </h1>
          <div
            v-if="selectedStaff.length === 0"
            class="flex items-center justify-center h-4/6"
          >
            <h1 class="font-mullish text-dkgray/50 italic font-bold m-0">
              No staff
            </h1>
          </div>
          <div v-else>
            <div
              v-for="(staff, i) in selectedStaff"
              :key="i"
              class="flex justify-between py-2 text-xs"
            >
              <span>
                {{ i + 1 }}:
                <strong class="ml-1"
                  >{{ staff.firstName.toUpperCase() }}
                  {{ staff.middleName.toUpperCase() }}
                  {{ staff.lastName.toUpperCase() }}</strong
                >
              </span>
              <span
                @click="removeItem(staff)"
                class="text-[red] cursor-pointer font-bold"
              >
                remove
              </span>
            </div>
          </div>
        </div>

        <button
          v-if="selectedStaff.length !== 0"
          class="bg-idBlued text-[12px] p-2 w-full mt-4 rounded m-auto text-[#fff] flex items-center justify-center"
          @click="submit"
          :disabled="processing || selectedStaff.length === 0"
        >
          Proceed<spinner v-if="processing"></spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SystemSettings",
  data() {
    return {
      processing: false,
      staffInfo: null,
      form: {
        verificationCode: null,
        action: null,
      },
      selectedStaff: [],
      showError: false,
      actions: [
        {
          name: "Reset Account",
          action: "reset-account",
          canDoMultiple: true,
        },
        {
          name: "Reset BVN (delete the bvn record)",
          action: "reset-bvn",
          canDoMultiple: true,
        },
        {
          name: "Rematch & Update BVN",
          action: "rematch-bvn-and-update-bvn",
          canDoMultiple: false,
        },
        {
          name: "Skip BVN",
          action: "skip-bvn",
          canDoMultiple: true,
        },
        {
          name: "Skip Biometrics",
          action: "skip-biometrics",
          canDoMultiple: true,
        },
        {
          name: "Can Accredit By Proxy",
          action: "accredit-by-proxy",
          canDoMultiple: true,
        },
        {
          name: "Bypass Microfinance",
          action: "bypass-microfinance",
          canDoMultiple: true,
        },
        {
          name: "Resend Certificates",
          action: "send-message",
          canDoMultiple: true,
        },
      ],
      rematchInfo: {
        firstName: undefined,
        lastName: undefined,
        middleName: undefined,
      },
    };
  },

  computed: {
    addReady() {
      if (!this.form.action) return false;

      switch (this.form.action) {
        case "send-message":
          if (this.staffInfo.approvalStep >= 2) return true;
          return false;
        default:
          return true;
      }
    },
    addErrorMsg() {
      if (!this.form.action) return "";

      switch (this.form.action) {
        case "send-message":
          if (this.staffInfo.approvalStep >= 2) return "";
          return "Can't send certificate, Verification is not complete";
        default:
          return "Staff record not complete";
      }
    },
    formReady() {
      return (
        this.form.verificationCode &&
        this.form.verificationCode.length >= 5 &&
        this.form.action
      );
    },
  },
  methods: {
    isPermited(act) {
      return Boolean(
        this.$store.state.admin.permissions.find(
          (perm) => perm.action === act.toLowerCase()
        )
      );
    },
    addStaffToList() {
      if (!this.addReady) {
        this.$swal({
          icon: "error",
          text: "Make sure all fields are valid",
        });
      }

      this.selectedStaff.push({
        verificationCode: this.staffInfo.verificationCode,
        firstName:
          this.form.action === "rematch-bvn-and-update-bvn"
            ? this.rematchInfo.firstName
            : this.staffInfo.firstName,
        lastName:
          this.form.action === "rematch-bvn-and-update-bvn"
            ? this.rematchInfo.lastName
            : this.staffInfo.lastName,
        middleName:
          this.form.action === "rematch-bvn-and-update-bvn"
            ? this.rematchInfo.middleName
            : this.staffInfo.middleName,
        email: this.staffInfo.email,
        phoneNumber: this.staffInfo.phoneNumber,
      });

      this.selectedStaff = [
        ...new Map(
          this.selectedStaff.map((item) => [item["verificationCode"], item])
        ).values(),
      ];
    },
    removeItem(item) {
      this.selectedStaff.splice(this.selectedStaff.indexOf(item), 1);
    },
    initialize() {
      this.processing = false;
      this.staffInfo = null;
      this.showError = false;
      this.form = {
        verificationCode: null,
        action: null,
      };
      this.selectedStaff = [];
      this.rematchInfo = {
        firstName: undefined,
        lastName: undefined,
        middleName: undefined,
      };
    },
    async submit() {
      try {
        const ids = this.selectedStaff.map((el) => el.verificationCode);
        this.processing = true;
        const payload =
          this.form.action === "bypass-microfinance"
            ? { action: this.form.action }
            : {
                ids,
                firstName: this.rematchInfo?.firstName.toLowerCase(),
                lastName: this.rematchInfo?.lastName.toLowerCase(),
                middleName: this.rematchInfo?.middleName.toLowerCase(),
                action: this.form.action,
              };

        let res;
        if (this.form.action == "send-message") {
          res = await this.$http.post("/send-certificates", {
            baseUrl: `${window.location.origin}/certificate`,
            employeeIds: ids,
          });
        } else {
          res = await this.$http.post("/account-service", payload);
        }

        if (!res) {
          return;
        }

        const { data } = res;

        this.initialize();
        this.$swal({ text: data.message, icon: "success" });
      } catch (error) {
        console.log({ error });
      }
    },
    async fetchDetails() {
      //check permission
      if (!this.isPermited(this.form.action)) {
        this.$swal({
          text: "You don't have permission to carry out this action. Please contact admin",
          icon: "error",
        });

        return;
      }
      //check if action is reset bnv
      if (
        this.form.action === "rematch-bvn-and-update-bvn" &&
        this.selectedStaff.length !== 0
      ) {
        this.showError = true;

        setTimeout(() => this.showError === false, 1000);

        return;
      }

      try {
        this.processing = true;
        const res = await this.$http.post("/users/employee", {
          verificationCode: this.form.verificationCode,
        });

        if (!res) {
          this.processing = false;
          return;
        }

        const { data } = res;

        this.staffInfo = data.data;

        this.rematchInfo.firstName = this.staffInfo.firstName;
        this.rematchInfo.lastName = this.staffInfo.lastName;
        this.rematchInfo.middleName = this.staffInfo.middleName;

        this.processing = false;
        this.form.verificationCode = null;
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
form button[type="submit"],
.proceed-btn {
  @apply w-full bg-oneGreen text-white font-semibold py-3 rounded-lg flex justify-center items-center gap-2;
}
.custome-input {
  @apply shadow appearance-none border rounded outline-none  py-2 px-3 text-gray-700 leading-tight  w-[170px] uppercase;
}
</style>
